import restaurant from "./images/capitoles.jpg";
import biserica from "./images/biserica.jpg";
import img_card from "./images/6.jpeg";
import imgheaderrestaurant from "./images/headerrestaurant.jpg";
import imgheader from "./images/fon.jpg";
import imgheaderm from "./images/fon.jpg";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/header.jpg";
import imgheadermiinimb from "./images/header.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Petru",
        mireasa: "Carolina",
        data: "28 Aprilie 2023",
        data_confirmare: "",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "anusic007@gmail.com", 
       tel: "+37368021610",
       phone: "tel:+37368021610",
       viber: "viber://chat?number=%2B37368021610",
       whatsapp: "https://wa.me/+37368021610",
       messenger: "https://www.messenger.com/t/",
       tel1: "+373xxx",
       phone1: "tel:+373xxx",
       viber1: "viber://chat?number=%2B373xxx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/iurii.denis",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Cununia Religioasă",
            localul: "Biserica Donici",
            name: "Adormirea Măicii Domnului",
            data: "28 Aprilie 2023, vineri, ora 12:00",
            adress: "r-l. Orhei, s. Donici, Moldova",
            harta: "https://goo.gl/maps/jxWDPwidDgTbrrfK7",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10892.148700719732!2d28.7480613!3d46.9609596!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8a5a5f5d7adcc261!2sTesalia!5e0!3m2!1sro!2s!4v1648740787752!5m2!1sro!2s"
        }, 
        {
            id: 2,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Capitoles Park, sala Piano",
            data: "28 Aprilie 2023, vineri, ora 18:00",
            adress: "Strada Melestiu 13a, Chișinău",
            harta: "https://goo.gl/maps/Y77GfM6TZUJRJQQV6",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10841.768537204549!2d27.8018441!3d47.2079306!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa5831fa218054710!2zUGFsYXR1bCBTxINyYsSDdG9yaWk!5e0!3m2!1sro!2s!4v1667940052968!5m2!1sro!2s"
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: "",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
             
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],

}

export default data;